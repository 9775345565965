import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.css";
import useClickOutside from "../CustomHooks/useClickOutside";

function SideBar({setSideBar}) {
  // const [sidebar, setSideBar] = useState(false);
  // let sidebarref = useRef();
  // useEffect(() => {
  //   getdrawer();
  // }, [sidebar]);

  // const clickhandler = () => {
  //   setSideBar(!sidebar);
  // };

  // useClickOutside(sidebarref, clickhandler);

 
  return (
    <div
      // ref={sidebarref}
      className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white"
      id="logo"
    >
      <div className="navbar-vertical-container">
        <div className="navbar-vertical-footer-offset pb-0">
          <div className="d-flex align-items-center justify-content-between">
          <div >
              <NavLink   className="navbar-brand" to="/dashboard" aria-label="Front">
                <img
                  className="navbar-brand-logo"
                  src="/assets/img/blue-logo.png"
                  alt="Logo"
                  data-hs-theme-appearance="default"
                />
              </NavLink>

          </div>
          <div className="closeBtn d-xl-none d-block" onClick={ () => setSideBar(false)}>
          <svg stroke="currentColor" fill="currentColor" className="text-black me-2" stroke-width="0" viewBox="0 0 512 512" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path></svg>
          </div>
          </div>
          <button
            type="button"
            className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler  d-none d-xl-block"
          >
            <i
              className="bi-arrow-bar-left navbar-toggler-short-align"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Collapse"
            ></i>
            <i
              className="bi-arrow-bar-right navbar-toggler-full-align"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Expand"
            ></i>
          </button>
          <div className="navbar-vertical-content">
            <div
              id="navbarVerticalMenu"
              className="nav nav-vertical card-navbar-nav"
            >
              <div className="nav-item">
                <NavLink
                  className="nav-link dropdown-toggle collapsed"
                  to="/dashboard"
                >
                  <i className="bi-house-door nav-icon"></i>
                  <span className="nav-link-title">Dashboards</span>
                </NavLink>
                <div
                  id="navbarVerticalMenuDashboards"
                  className="nav-collapse collapse"
                  data-bs-parent="#navbarVerticalMenu"
                >
                  <span className="nav-link active">Default</span>
                  <span className="nav-link ">Alternative</span>
                </div>
              </div>
              <div id="navbarVerticalMenuPagesMenu">
                <div className="nav-item">
                  <NavLink className="nav-link " to="/manageusers">
                    <i className="bi-people nav-icon"></i>
                    <span className="nav-link-title">Manage Users</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink className="nav-link " to="/managebots">
                    <i className="bi bi-robot nav-icon"></i>
                    <span className="nav-link-title">Manage Bots</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink className="nav-link " to="/managesubscriptionplans">
                    <i className="bi-person nav-icon"></i>
                    <span className="nav-link-title">
                      Manage Subscription Plans
                    </span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink className="nav-link " to="/managehealthcategories">
                    <i className="bi bi-clipboard2-plus nav-icon"></i>
                    <span className="nav-link-title">
                      Manage Health Categories
                    </span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink className="nav-link" to="/managehealtharticles">
                    <i className="bi bi-journal-text nav-icon"></i>
                    <span className="nav-link-title">
                      Manage Health Articles
                    </span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink className="nav-link " to="/manageeventtemplate">
                    <i className="bi bi-file-richtext nav-icon"></i>
                    <span className="nav-link-title">
                      Manage Events Templates
                    </span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink className="nav-link " to="/managepublicevent">
                    <i className="bi-people nav-icon"></i>
                    <span className="nav-link-title">Manage Public Events</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink
                    className="nav-link  collapsed"
                    to="/managecorporateevents"
                  >
                    <i className="bi-briefcase nav-icon"></i>
                    <span className="nav-link-title">
                      Manage Corporate Events
                    </span>
                  </NavLink>
                </div>

                <div className="nav-item">
                  <NavLink
                    className="nav-link "
                    to="/manageclubs"
                    data-placement="left"
                  >
                    <i className="bi-key nav-icon"></i>
                    <span className="nav-link-title">Manage Clubs</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink
                    className="nav-link "
                    data-placement="left"
                    to="/ManageMerchandise"
                  >
                    <i className="bi bi-shop-window nav-icon"></i>
                    <span className="nav-link-title">Manage Merchandise</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink className="nav-link " to="/raisedquery">
                    <i className="bi bi-patch-question nav-icon"></i>
                    <span className="nav-link-title">
                      Manage Raised Queries
                    </span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink
                    className="nav-link "
                    to="/managereports"
                    data-placement="left"
                  >
                    <i className="bi bi-pencil-square nav-icon"></i>
                    <span className="nav-link-title">Manage Reports</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink
                    className="nav-link "
                    to="/manageearning"
                    data-placement="left"
                  >
                    <i className="bi bi-wallet2 nav-icon"></i>
                    <span className="nav-link-title">Manage Earnings</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink
                    className="nav-link "
                    to="/manageorder"
                    data-placement="left"
                  >
                    <i className="bi-box-seam nav-icon"></i>
                    <span className="nav-link-title">Manage Orders</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink
                    className="nav-link "
                    to="/managecms"
                    data-placement="left"
                  >
                    <i className="bi bi-pc-display-horizontal nav-icon"></i>
                    <span className="nav-link-title">Manage CMS</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <div className="nav-link " to="#" data-placement="left">
                    <i className="bi bi-bell nav-icon"></i>
                    <span className="nav-link-title">Manage Notifications</span>
                  </div>
                </div>
                <div className="nav-item">
                  <NavLink
                    className="nav-link "
                    to="/team"
                    data-placement="left"
                  >
                    <i className="bi bi-pc-display-horizontal nav-icon"></i>
                    <span className="nav-link-title">Manage Teams</span>
                  </NavLink>
                </div>
                <div className="nav-item">
                  <NavLink
                    className="nav-link "
                    to="/Event-Images"
                    data-placement="left"
                  >
                    <i className="bi-key nav-icon"></i>
                    <span className="nav-link-title">Manage Event Images</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
