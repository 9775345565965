import React from "react";
import { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { getData, postData, putData } from "../../FetchService";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";

function ManageBots() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [row, setRow] = useState([]);
  const [name, setName] = useState("");
  const apidata = async () => {
    setLoader(true);
    const res = await getData("bots");
    console.log({ res });
    setData(res.data);
    setLoader(false);
  };
  useEffect(() => {
    apidata();
  }, []);

  const editHandler = async (id) => {
    const res = await postData(`edit-subscription-plan/${id}`);
    if (res.status) {
      apidata();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const navigate = useNavigate();

  const columns = [
    {
      name: "SR.NO",

      cell: (row, i) => i + 1,
      sortable: true,
      center: true,
    },
    {
      name: "FIRST NAME",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "LAST NAME",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "MOBILE",
      selector: (row) => row.mobile,
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <div
            style={{ marginRight: "16px" }}
            className="btn btn-white btn-sm border-0  "
            data-bs-toggle="modal"
            data-bs-target="#addEditCealthCategories"
            onClick={() => {
              navigate("/managebots/createbot", {
                state: {
                  isEdit: true,
                  ...row,
                },
              });
            }}
          >
            <i className="bi-pencil-fill me-1"></i>
          </div>
          <div
            className="btn btn-white btn-sm border-0"
            data-bs-toggle="modal"
            data-bs-target="#askdeletecategory"
            onClick={() => {
              setRow(row._id);
              setName(row.name);
            }}
          >
            <i className="bi bi-trash-fill"></i>
          </div>
        </div>
      ),
      sortable: true,
      center: true,
    },
  ];

  const rowHandler = async (id) => {
    try {
      const res = await putData(`delete-bots`, { id: [id] });
      if (res.status) {
        setRow([]);
        setName("");
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        apidata();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid subscriptionOuter">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title m-0">Manage Bots</h1>
              </div>
              <div className="col-auto">
                <NavLink to="/managebots/createbot">
                  <button className="btn btn-dark pageBtn" type="button">
                    Add New Bot
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          {loader ? (
            <div style={{ marginLeft: "400px" }}>
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{ marginTop: "100px" }}
                wrapperclassName="blocks-wrapper"
                colors={["#b8c480", "#B2A3B5", "#F4442E", "#51E5FF", "#429EA6"]}
              />
            </div>
          ) : (
            <div className="card">
              <div className="table-responsive datatable-custom rounded-top  subscriptionInner">
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  fixedHeaderScrollHeight="408px"
                  highlightOnHover
                  customStyles={{
                    headCells: {
                      style: {
                        width: "250px",
                        whiteSpace: "nowrap",
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="modal fade"
        id="askdeletecategory"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-danger"
                id="exampleModalLongTitle"
              >
                Alert !
              </h5>
            </div>
            <div className="modal-body">
              Do you want to delete the categorie -<strong>{name}</strong>
              <strong>{row.categories}</strong>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => rowHandler(row)}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setName("");
                  setRow([]);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageBots;
