import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { postData, getData, putData } from "../../FetchService";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import ErrorMessages from "../../CommonPages/ErrorMessages";

const CreateBot = ({ imageSeter, image, convertImageToUrl }) => {
  let seter = imageSeter;
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [valImage, setValImage] = useState("");
  const [valTemplate, setValTemplate] = useState("");
  const [data, setData] = useState([]);
  const [tempId, setTempId] = useState([]);
  const [images, setImages] = useState([]);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
  });
  const [blob, setBlob] = useState("");

  const handleFormValues = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleTempId = (e) => {
    let idd = e.target.value;
    let a = data?.filter((item) => {
      return item._id == idd;
    });
    let fill = tempId.filter((el) => el._id == idd);
    if (!fill.length) {
      setTempId([...tempId, a[0]]);
    }
  };

  const handleGetMangeData = (id) => {
    let b = tempId;
    if (b) {
      b = tempId.filter((el) => {
        return el._id !== id;
      });
    }

    setTempId(b);
  };

  const apiHandler = async () => {
    let res = await getData("health-categories");

    setData(res.data);
  };
  useEffect(() => {
    apiHandler();
    return () => {
      setBlob("");
      seter("");
    };
  }, []);
  let a;
  const handleAddEvent = async (value) => {
    if (state?.isEdit) {
      const data = {
        id: state._id,
        firstName: value.firstName,
        image: image,
        lastName: value.lastName,
        mobile: value.mobile,
      };
      const res = await putData("edit-bot", data);
      if (res.status === true) {
        seter("");
        setBlob("");
        setTimeout(() => {
          navigate("/managebots", { state: { data: data } });
        }, 1000);
        toast.success(" Added successfully  !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return;
    }

    myValidation();
    a = tempId?.map((el) => {
      return el._id;
    });

    const data = {
      firstName: value.firstName,
      image: image,
      lastName: value.lastName,
      mobile: value.mobile,
    };

    if (!valImage || !valTemplate) {
      const res = await postData("create-bot", data);
      if (res.status === true) {
        seter("");
        setBlob("");
        setTimeout(() => {
          navigate("/managebots", { state: { data: data } });
        }, 1000);
        toast.success(" Added successfully  !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Something went wrong !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const healthArticleValidation = yup.object().shape({
    firstName: yup.string().required("First Name is required !"),
    lastName: yup.string().required("Last Name is required !"),
    mobile: yup
      .string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
  });

  const myValidation = () => {
    if (!tempId.length) {
      setValTemplate("Minimum 1 template is required !");
    } else {
      setValTemplate("");
    }
    if (!blob) {
      setValImage("Image is required !");
    } else {
      setValImage("");
    }
  };

  useEffect(() => {
    if (state) {
      if (state.isEdit) {
        setFormValues({
          firstName: state.firstName,
          lastName: state.lastName,
          mobile: state.mobile,
        });
        imageSeter(state.image);
      }
    }
  }, [state]);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid addEditHealthArticles">
          <div className="page-header border-0 pb-0">
            <div className="row align-items-center">
              <div className="col-12 col-sm d-flex align-items-center">
                <div className="row align-items-center g-3 mb-2">
                  <div className="col">
                    <h1 className="page-header-title mb-0">
                      Add Health Article
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Formik
            onSubmit={handleAddEvent}
            initialValues={formValues}
            enableReinitialize
            validationSchema={healthArticleValidation}
          >
            {({ values, errors, touched }) => {
              return (
                <Form>
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="row g-2 mb-4">
                        <div className="col-12 col-sm-6 col-md-5 col-lg-4">
                          <div className="card">
                            <div className="card-body p-0">
                              <label
                                htmlFor="attachFilesNewProjectLabel"
                                className="js-dropzone dz-dropzone dz-dropzone-card"
                              >
                                <div className="dz-message">
                                  <>
                                    <img
                                      className="avatar avatar-xl avatar-4x3 mb-3"
                                      src={image}
                                      alt="Image Description"
                                      data-hs-theme-appearance="default"
                                    />
                                    <img
                                      className="avatar avatar-xl avatar-4x3 mb-3 d-none"
                                      src={image}
                                      alt="Image Description"
                                      data-hs-theme-appearance="dark"
                                    />
                                  </>

                                  <h5>Drag and drop your file here</h5>
                                  <p className="mb-2">or</p>
                                  <span className="btn btn-white btn-sm">
                                    Browse files
                                  </span>
                                </div>
                              </label>
                              <Field
                                type="file"
                                className="d-none"
                                name="image"
                                id="attachFilesNewProjectLabel"
                                onChange={(e) => {
                                  convertImageToUrl(e);
                                  setBlob(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                  if (blob) {
                                    setValImage("");
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="text-dark mt-2">Upload Bot Image</div>
                        </div>
                        {valImage && (
                          <div className="text-danger mt-2">{valImage}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-lg-6">
                          <div className="col-12">
                            <div className="text-dark mt-2 mb-2">
                              First name
                            </div>
                          </div>
                          <Field
                            type="text"
                            className="form-control"
                            name="firstName"
                            placeholder="Enter First Name"
                          />
                          {errors.firstName && touched.firstName ? (
                            <ErrorMessage
                              name="firstName"
                              component={ErrorMessages}
                            />
                          ) : null}
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="col-12">
                            <div className="text-dark mt-2 mb-2">Last name</div>
                          </div>
                          <Field
                            type="text"
                            className="form-control"
                            name="lastName"
                            placeholder="Enter Last Name"
                          />
                          {errors.lastName && touched.lastName ? (
                            <ErrorMessage
                              name="lastName"
                              component={ErrorMessages}
                            />
                          ) : null}
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="col-12">
                            <div className="text-dark mt-2 mb-2">Mobile</div>
                          </div>
                          <Field
                            type="text"
                            className="form-control"
                            name="mobile"
                            placeholder="Enter Phone Number"
                          />
                          {errors.mobile && touched.mobile ? (
                            <ErrorMessage
                              name="mobile"
                              component={ErrorMessages}
                            />
                          ) : null}
                        </div>

                        <div className="col-12">
                          <button className="btn btn-dark px-5" type="submit">
                            ADD
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateBot;
